import React, { useEffect, useRef, useState } from "react";
import { createRoot } from "react-dom/client";
import { useMap } from "react-map-gl";
import mapboxgl from "mapbox-gl";
import * as turf from "@turf/turf";

import { useGlobalState } from "../pages/app-container";
import CadastreServices from "../services/cadastre";

import catchmentAreaIcon from "../assets/images/catchment-area.png";
import "../style/catchment-area.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import CatchmentAreaResultPopup from "./catchment-area-result-popup";

const requestIsochrone = async (
    lng,
    lat,
    travelTime,
    travelDistance,
    requestMode,
    nbTerrains
) => {
    let response;

    if (requestMode === "temps" || requestMode === "distance") {
        switch (requestMode) {
            case "temps":
                if (nbTerrains > 0) {
                    response = await fetch(
                        `https://api.mapbox.com/isochrone/v1/mapbox/driving/${lng},${lat}?contours_minutes=${travelTime},${
                            travelTime * 2
                        }&polygons=true&access_token=pk.eyJ1Ijoicm11bnRkcyIsImEiOiJjbGRucGtxZTgwazBpM3BxZzdpOXBsdTlkIn0.Ixv9Rh6eRAgrN0hkYWi8KA`
                    );
                } else {
                    response = await fetch(
                        `https://api.mapbox.com/isochrone/v1/mapbox/driving/${lng},${lat}?contours_minutes=${travelTime}&polygons=true&access_token=pk.eyJ1Ijoicm11bnRkcyIsImEiOiJjbGRucGtxZTgwazBpM3BxZzdpOXBsdTlkIn0.Ixv9Rh6eRAgrN0hkYWi8KA`
                    );
                }
                break;
            case "distance":
                if (nbTerrains > 0) {
                    response = await fetch(
                        `https://api.mapbox.com/isochrone/v1/mapbox/driving/${lng},${lat}?contours_meters=${
                            travelDistance * 1000
                        },${
                            travelDistance * 1000 * 2
                        }&polygons=true&access_token=pk.eyJ1Ijoicm11bnRkcyIsImEiOiJjbGRucGtxZTgwazBpM3BxZzdpOXBsdTlkIn0.Ixv9Rh6eRAgrN0hkYWi8KA`
                    );
                } else {
                    response = await fetch(
                        `https://api.mapbox.com/isochrone/v1/mapbox/driving/${lng},${lat}?contours_meters=${
                            travelDistance * 1000
                        }&polygons=true&access_token=pk.eyJ1Ijoicm11bnRkcyIsImEiOiJjbGRucGtxZTgwazBpM3BxZzdpOXBsdTlkIn0.Ixv9Rh6eRAgrN0hkYWi8KA`
                    );
                }
                break;
            default:
                break;
        }

        const data = await response.json();

        if (data.features.length === 2) {
            data.features[0].properties.fillColor = "#ccad00";
            data.features[1].properties.fillColor = "#0088cc";
        } else if (data.features.length === 1) {
            data.features[0].properties.fillColor = "#0088cc";
        }

        return data;
    } else {
        return;
    }
};

const requestPopulationForCity = async (commune) => {
    const population = await fetch(
        `https://geo.api.gouv.fr/communes?code=${commune.properties.id}`
    );
    const data = await population.json();
    return data?.[0].population;
};

const getCitiesInNearDepartments = async (marker) => {
    const dpt = CadastreServices.getDepartementDataByCoords(
        marker._lngLat.lng,
        marker._lngLat.lat
    );

    let cities = [];

    if (dpt) {
        const adjacentsDepartments =
            CadastreServices.getAdjacentDepartementsByDepartement(dpt);
        const nearDepartments = CadastreServices.getNearAdjacentDepartements(
            marker,
            adjacentsDepartments
        );

        for (const department of nearDepartments) {
            const result = await CadastreServices.getCommunesByDepartement(
                department.properties.code
            );
            if (result !== null) {
                cities.push(result);
            }
        }
    }

    return cities;
};

const calculateWeightedPopulation = (
    marker,
    userTerrains,
    cities,
    clubsInZone,
    distanceImpactFactor = 1
) => {
    const userPoint = {
        type: "Feature",
        geometry: {
            coordinates: [marker.lng, marker.lat],
            type: "Point",
        },
        properties: {},
    };

    let results = {
        totalPopulationUser: 0,
        cities: [],
    };

    // 3. Calculer l'impact pour chaque ville
    cities.forEach((city) => {
        const cityLocation = turf.centroid(city.commune.geometry);

        const distanceUser = turf.distance(cityLocation.geometry, userPoint, {
            units: "kilometers",
        });

        const impactUser =
            userTerrains /
            (Math.pow(distanceUser, 2 * distanceImpactFactor) + 1e-6);

        let totalImpact = impactUser;

        // Ajouter l'impact des clubs concurrents
        clubsInZone?.[0]?.features?.forEach((club) => {
            const distanceClub = turf.distance(cityLocation, club.geometry, {
                units: "kilometers",
            });

            const impactClub =
                club.properties.indoor /
                (Math.pow(distanceClub, 2 * distanceImpactFactor) + 1e-6);

            totalImpact += impactClub;
        });

        // Répartir la population de la ville
        const populationPondéréeUser = Math.round(
            city.populationCalculee * (impactUser / totalImpact)
        );

        const cityResult = {
            ...city,
            impactUser: impactUser,
            totalImpact: totalImpact,
            populationPonderee: populationPondéréeUser,
        };

        results.cities.push(cityResult);
        results.totalPopulationUser += populationPondéréeUser;
    });

    return results;
};

const getCatchmentAreaData = async (
    communesPlusAdjacentDepartmentsData,
    catchmentAreaData,
    padelsData
) => {
    let catchementAreaResult = {
        totalPopulation: 0,
        cities: [],
        padels: [],
    };

    let catchmentAreaZone;
    let padelSearchingZone;

    if (catchmentAreaData.features.length === 2) {
        catchmentAreaZone = catchmentAreaData.features[1];
        padelSearchingZone = catchmentAreaData.features[0];
    } else if (catchmentAreaData.features.length === 1) {
        catchmentAreaZone = catchmentAreaData.features[0];
        padelSearchingZone = catchmentAreaData.features[0];
    }

    for (const commune of communesPlusAdjacentDepartmentsData.features) {
        // Calcule l'intersection entre la commune et la zone d'isochrone
        const intersection = turf.intersect(commune, catchmentAreaZone);

        if (intersection) {
            const populationCommune = await requestPopulationForCity(
                commune
            ).then((data) => {
                return data;
            });

            // Calcule les surfaces (en km² par exemple)
            const surfaceCommune = turf.area(commune);
            const surfaceIntersection = turf.area(intersection);

            if (catchmentAreaZone === padelSearchingZone) {
                if (populationCommune >= 100000) {
                    // Calcule la population proportionnelle dans la zone
                    const populationProportionnelle =
                        populationCommune *
                        (surfaceIntersection / surfaceCommune);

                    catchementAreaResult.cities.push({
                        commune,
                        populationCommune,
                        populationCalculee: Math.round(
                            populationProportionnelle
                        ),
                        calculateProportianalPopulation: true,
                        surfaceCommune,
                        surfaceIntersection,
                    });

                    // Ajoute à la population totale
                    catchementAreaResult.totalPopulation += Math.round(
                        populationProportionnelle
                    );
                } else {
                    catchementAreaResult.cities.push({
                        commune,
                        populationCommune,
                        populationCalculee: populationCommune,
                        calculateProportianalPopulation: false,
                        surfaceCommune,
                        surfaceIntersection,
                    });

                    // Ajoute à la population totale
                    catchementAreaResult.totalPopulation += populationCommune;
                }
            } else {
                // Calcule la population proportionnelle dans la zone
                const populationProportionnelle =
                    populationCommune * (surfaceIntersection / surfaceCommune);

                catchementAreaResult.cities.push({
                    commune,
                    populationCommune,
                    populationCalculee: Math.round(populationProportionnelle),
                    calculateProportianalPopulation: true,
                    surfaceCommune,
                    surfaceIntersection,
                });

                // Ajoute à la population totale
                catchementAreaResult.totalPopulation += Math.round(
                    populationProportionnelle
                );
            }
        }
    }

    // Recherche des clubs de padels dans la zone
    const padelsInsideZone = turf.pointsWithinPolygon(
        padelsData,
        padelSearchingZone
    );

    const existingPadels = padelsInsideZone?.features?.filter(
        (padel) => padel.properties.existing
    );

    const existingPadelsWithIndoor = {
        type: "FeatureCollection",
        features: existingPadels?.filter(
            (padel) => padel.properties.indoor > 0
        ),
    };

    if (existingPadelsWithIndoor?.features?.length > 0) {
        catchementAreaResult.padels.push(existingPadelsWithIndoor);
    }

    return catchementAreaResult;
};

const CatchmentAreaPopup = ({
    travelTime,
    travelDistance,
    requestMode,
    nbTerrains,
    setRequestMode,
    handleChange,
    onClose,
    calculatePopulationInCatchmentArea,
    citiesInCatchementArea,
    openResultPopup,
    resultPopupClosed,
}) => {
    const handleSubmit = (e) => {
        e.preventDefault();
        calculatePopulationInCatchmentArea();
    };

    return (
        <div className="catchment-area-popup-container">
            <FontAwesomeIcon
                icon="fa-solid fa-close"
                id="catchment-area-popup-close"
                onClick={onClose}
            />
            <div className="catchment-area-popup-header">
                <img
                    src={catchmentAreaIcon}
                    alt="Icone de zone de chalandise"
                    height="24px"
                />
                <h3>Zone de chalandise (ZC)</h3>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="catchment-area-popup-content">
                    <span className="catchment-area-popup-label">
                        Cliquez sur la carte pour définir le point de départ
                    </span>
                </div>
                <div className="catchment-area-popup-content">
                    <button
                        type="button"
                        className={requestMode === "temps" ? "active" : ""}
                        onClick={() => setRequestMode("temps")}
                    >
                        Temps
                    </button>
                    <button
                        type="button"
                        className={requestMode === "distance" ? "active" : ""}
                        onClick={() => setRequestMode("distance")}
                    >
                        Distance
                    </button>
                </div>
                {requestMode === "temps" && (
                    <div className="catchment-area-popup-content">
                        <span className="catchment-area-popup-label">
                            Temps de trajet :
                        </span>
                        <span className="catchment-area-popup-value">
                            <input
                                name="travelTime"
                                type="number"
                                min={0}
                                max={30}
                                value={travelTime}
                                onChange={handleChange}
                            />{" "}
                            minutes
                        </span>
                    </div>
                )}
                {requestMode === "distance" && (
                    <div className="catchment-area-popup-content">
                        <span className="catchment-area-popup-label">
                            Distance :
                        </span>
                        <span className="catchment-area-popup-value">
                            <input
                                name="travelDistance"
                                type="number"
                                min={0}
                                max={25}
                                value={travelDistance}
                                onChange={handleChange}
                            />{" "}
                            km
                        </span>
                    </div>
                )}
                <div className="catchment-area-popup-content">
                    <span className="catchment-area-popup-label">
                        Nombre de pistes :
                    </span>
                    <span className="catchment-area-popup-value">
                        <input
                            name="nbTerrains"
                            type="number"
                            min={0}
                            max={20}
                            value={nbTerrains}
                            onChange={handleChange}
                        />{" "}
                        pistes
                    </span>
                </div>
                <div className="catchment-area-popup-footer">
                    <button type="submit">Valider</button>
                    {citiesInCatchementArea.length > 0 && (
                        <button
                            type="button"
                            onClick={
                                () => openResultPopup()
                                // setIsCatchmentAreaResultPopupActive(true)
                            }
                            disabled={!resultPopupClosed}
                        >
                            Voir résultats
                        </button>
                    )}
                </div>
            </form>
        </div>
    );
};

function CatchmentArea({ onActiveChange, padelsData }) {
    const [hideCollectionTab, setHideCollectionTab] =
        useGlobalState("hideCollectionTab");
    const [citiesInCatchementArea, setCitiesInCatchementArea] = useGlobalState(
        "citiesInCatchementArea"
    );

    const { current: map } = useMap();

    const [
        communesPlusAdjacentDepartmentsData,
        setCommunesPlusAdjacentDepartmentsData,
    ] = useState([]);

    const [isCatchmentAreaPopupActive, setIsCatchmentAreaPopupActive] =
        useState(false);

    const [marker, setMarker] = useState(null);
    const [travelTime, setTravelTime] = useState(0);
    const [travelDistance, setTravelDistance] = useState(0);
    const [requestMode, setRequestMode] = useState("temps");
    const [nbTerrains, setNbTerrains] = useState(0);
    const [padelsInZone, setPadelsInZone] = useState(0);
    const [calculateUserPopulation, setCalculateUserPopulation] =
        useState(false);

    const [totalPopulationInCatchmentArea, setTotalPopulationInCatchmentArea] =
        useState(0);
    const [
        userTotalPopulationInCatchmentArea,
        setUserTotalPopulationInCatchmentArea,
    ] = useState(0);

    const [resultPopup, setResultPopup] = useState(null);

    const RESULT_POPUP_MIN_WIDTH = 800;
    const RESULT_POPUP_MIN_HEIGHT = 600;

    useEffect(() => {
        map.on("click", handleMapClick);

        return () => {
            map.off("click", handleMapClick);
        };
    });

    useEffect(() => {
        if (
            resultPopup &&
            !resultPopup.closed &&
            totalPopulationInCatchmentArea
        ) {
            resultPopup.document.title = "Résultats des calculs";
            resultPopup.document.body.innerHTML = "<div id='popup-root'></div>";

            // Copier les styles existants de la fenêtre principale
            const head = document.querySelector("head");
            const popupHead = resultPopup.document.head;
            Array.from(
                head.querySelectorAll("style, link[rel='stylesheet']")
            ).forEach((styleOrLink) => {
                popupHead.appendChild(styleOrLink.cloneNode(true));
            });

            // Créer un conteneur pour React dans la nouvelle fenêtre
            const container = resultPopup.document.getElementById("popup-root");
            const root = createRoot(container);

            // Rendre le contenu React dans la nouvelle fenêtre
            root.render(
                <CatchmentAreaResultPopup
                    totalPopulationInCatchmentArea={
                        totalPopulationInCatchmentArea
                    }
                    userTotalPopulationInCatchmentArea={
                        userTotalPopulationInCatchmentArea
                    }
                    padelsInZone={padelsInZone}
                    calculateUserPopulation={calculateUserPopulation}
                    recalculatePopulationInCatchmentArea={
                        recalculatePopulationInCatchmentArea
                    }
                />
            );

            // Nettoyage à la fermeture de la fenêtre
            // const timer = setInterval(() => {
            //     if (resultPopup.closed) {
            //         setResultPopup(null);
            //         clearInterval(timer);
            //     }
            // }, 500);

            // Vérifier la taille de la fenêtre périodiquement
            const timer = setInterval(() => {
                if (resultPopup.closed) {
                    setResultPopup(null);
                    clearInterval(timer);
                } else {
                    // Si la fenêtre est plus petite que la taille minimale, ajustez-la
                    if (
                        resultPopup.innerWidth < RESULT_POPUP_MIN_WIDTH ||
                        resultPopup.innerHeight < RESULT_POPUP_MIN_HEIGHT
                    ) {
                        resultPopup.resizeTo(
                            Math.max(
                                resultPopup.innerWidth,
                                RESULT_POPUP_MIN_WIDTH
                            ),
                            Math.max(
                                resultPopup.innerHeight,
                                RESULT_POPUP_MIN_HEIGHT
                            )
                        );
                    }
                }
            }, 100);

            return () => clearInterval(timer);
        }
    }, [resultPopup, totalPopulationInCatchmentArea]);

    const concatenateGeoJSONArrays = (geoJSONArrays) => {
        // Vérifier si geoJSONArrays est valide
        if (!Array.isArray(geoJSONArrays) || geoJSONArrays.length === 0) {
            console.error(
                "geoJSONArrays est vide ou invalide : ",
                geoJSONArrays
            );
            return;
        }

        // Utiliser reduce pour concaténer toutes les features
        const allCities = geoJSONArrays.reduce((acc, geoJSON) => {
            if (geoJSON.type !== "FeatureCollection") {
                console.error(
                    "Un objet GeoJSON n'est pas de type FeatureCollection : ",
                    geoJSON
                );
                return acc;
            }

            if (geoJSON.features && Array.isArray(geoJSON.features)) {
                return acc.concat(geoJSON.features);
            } else {
                console.warn(
                    "Pas de features valides pour cet objet GeoJSON : ",
                    geoJSON
                );
                return acc;
            }
        }, []);

        // Créer un nouvel objet GeoJSON avec les données concaténées
        const newArray = {
            type: "FeatureCollection",
            features: allCities, // Contient toutes les villes concaténées
        };

        return newArray;
    };

    useEffect(() => {
        const getCitiesFromMarker = async () => {
            if (marker) {
                const geoJSONArrays = await getCitiesInNearDepartments(marker);
                const newArray = concatenateGeoJSONArrays(geoJSONArrays);
                setCommunesPlusAdjacentDepartmentsData(newArray);
            }
        };
        getCitiesFromMarker();
    }, [marker]);

    const handleChange = (e) => {
        switch (e.target.name) {
            case "travelTime":
                if (e.target.value < 0) {
                    setTravelTime(0);
                } else if (e.target.value > 30) {
                    setTravelTime(30);
                } else {
                    setTravelTime(e.target.value);
                }
                break;
            case "travelDistance":
                if (e.target.value < 0) {
                    setTravelDistance(0);
                } else if (e.target.value > 25) {
                    setTravelDistance(25);
                } else {
                    setTravelDistance(e.target.value);
                }
                break;
            case "nbTerrains":
                if (e.target.value < 0) {
                    setNbTerrains(0);
                } else if (e.target.value > 20) {
                    setNbTerrains(20);
                } else {
                    setNbTerrains(e.target.value);
                }
                break;
            default:
                break;
        }
    };

    const handleMapClick = (e) => {
        if (isCatchmentAreaPopupActive) {
            if (marker) {
                removeMarker();
            }
            createMarker(e.lngLat);
        }
    };

    const resetCatchmentArea = () => {
        onActiveChange(false);
        removeMarker();
        setIsCatchmentAreaPopupActive(false);
        setHideCollectionTab(false);
        setTravelTime(0);
        setTravelDistance(0);
        setRequestMode("temps");
        setNbTerrains(0);
        setPadelsInZone(0);
        setCalculateUserPopulation(false);
        setTotalPopulationInCatchmentArea(0);
        setUserTotalPopulationInCatchmentArea(0);
        setCitiesInCatchementArea([]);
        if (resultPopup) {
            resultPopup.close();
        }
        setResultPopup(null);
    };

    const handleClick = (e) => {
        if (e.target.id === "catchment-area-icon") {
            if (!isCatchmentAreaPopupActive) {
                setHideCollectionTab(true);
                setIsCatchmentAreaPopupActive(true);
                onActiveChange(true);
            } else {
                resetCatchmentArea();
            }
        }
    };

    const handleCatchmentAreaPopupClose = () => {
        resetCatchmentArea();
    };

    const createMarker = (location) => {
        let markerElt = document.createElement("div");
        markerElt.className = "catchment-area-marker";
        markerElt.id = "catchment-area-marker";
        markerElt.innerText = "i";

        const marker = new mapboxgl.Marker({
            element: markerElt,
            draggable: false,
        });
        marker.setLngLat([location.lng, location.lat]);
        marker.addTo(map.getMap());
        setMarker(marker);
    };

    const removeMarker = () => {
        marker?.remove();
        setMarker(null);
    };

    const openResultPopup = () => {
        if (!resultPopup || resultPopup.closed) {
            const newPopup = window.open(
                "",
                "_blank",
                "width=1100,height=900,scrollbars=yes,resizable=yes"
            );
            setResultPopup(newPopup);
        }
    };

    const calculatePopulationInCatchmentArea = async () => {
        nbTerrains > 0
            ? setCalculateUserPopulation(true)
            : setCalculateUserPopulation(false);

        if (marker) {
            requestIsochrone(
                marker._lngLat.lng,
                marker._lngLat.lat,
                travelTime,
                travelDistance,
                requestMode,
                nbTerrains
            ).then(async (data) => {
                map.getSource("catchmentArea").setData(data);

                const catchmentAreaResult = await getCatchmentAreaData(
                    communesPlusAdjacentDepartmentsData,
                    data,
                    padelsData
                );

                setPadelsInZone(catchmentAreaResult.padels?.[0]?.features);

                const weightedPopulationInZone = calculateWeightedPopulation(
                    marker._lngLat,
                    nbTerrains,
                    catchmentAreaResult.cities,
                    catchmentAreaResult.padels
                );

                setTotalPopulationInCatchmentArea(
                    catchmentAreaResult.totalPopulation
                );
                setUserTotalPopulationInCatchmentArea(
                    weightedPopulationInZone.totalPopulationUser
                );

                setCitiesInCatchementArea(weightedPopulationInZone.cities);

                openResultPopup(resultPopup);
            });
        } else {
            console.log("No marker");
        }
    };

    const recalculatePopulationInCatchmentArea = (cities) => {
        let catchementAreaResult = {
            totalPopulation: 0,
            cities: [],
        };

        for (const city of cities) {
            // Calcule les surfaces (en km² par exemple)
            const surfaceCommune = city.surfaceCommune;
            const surfaceIntersection = city.surfaceIntersection;

            if (city.calculateProportianalPopulation) {
                // Calcule la population proportionnelle dans la zone
                const populationProportionnelle =
                    city.populationCommune *
                    (surfaceIntersection / surfaceCommune);

                catchementAreaResult.cities.push({
                    commune: city.commune,
                    populationCommune: city.populationCommune,
                    populationCalculee: Math.round(populationProportionnelle),
                    calculateProportianalPopulation: true,
                    surfaceCommune,
                    surfaceIntersection,
                });

                // Ajoute à la population totale
                catchementAreaResult.totalPopulation += Math.round(
                    populationProportionnelle
                );
            } else {
                catchementAreaResult.cities.push({
                    commune: city.commune,
                    populationCommune: city.populationCommune,
                    populationCalculee: city.populationCommune,
                    calculateProportianalPopulation: false,
                    surfaceCommune,
                    surfaceIntersection,
                });

                // Ajoute à la population totale
                catchementAreaResult.totalPopulation += city.populationCommune;
            }
        }

        setTotalPopulationInCatchmentArea(catchementAreaResult.totalPopulation);
        setCitiesInCatchementArea(catchementAreaResult.cities);
    };

    return (
        <div
            id="catchment-area-button"
            className={
                "catchment-area-button " +
                (isCatchmentAreaPopupActive && "active")
            }
            onClick={handleClick}
        >
            <img
                id="catchment-area-icon"
                src={catchmentAreaIcon}
                alt=""
                height="32px"
            />
            {isCatchmentAreaPopupActive && (
                <CatchmentAreaPopup
                    travelTime={travelTime}
                    travelDistance={travelDistance}
                    requestMode={requestMode}
                    nbTerrains={nbTerrains}
                    setRequestMode={setRequestMode}
                    handleChange={handleChange}
                    onClose={handleCatchmentAreaPopupClose}
                    calculatePopulationInCatchmentArea={
                        calculatePopulationInCatchmentArea
                    }
                    citiesInCatchementArea={citiesInCatchementArea}
                    openResultPopup={openResultPopup}
                    resultPopupClosed={
                        resultPopup?.closed === undefined ? true : false
                    }
                    // setIsCatchmentAreaResultPopupActive={
                    //     setIsCatchmentAreaResultPopupActive
                    // }
                />
            )}
            {/* {isCatchmentAreaResultPopupActive && (
                <CatchmentAreaResultPopup
                    totalPopulationInCatchmentArea={
                        totalPopulationInCatchmentArea
                    }
                    userTotalPopulationInCatchmentArea={
                        userTotalPopulationInCatchmentArea
                    }
                    citiesInCatchementArea={citiesInCatchementArea}
                    nbPadelsInZone={nbPadelsInZone}
                    calculateUserPopulation={calculateUserPopulation}
                    onClose={handleCatchmentAreaResultPopupClose}
                    calculatePopulationInCatchmentArea={
                        calculatePopulationInCatchmentArea
                    }
                    isCatchmentAreaResultPopupLoading={
                        isCatchmentAreaResultPopupLoading
                    }
                    setCitiesInCatchementArea={setCitiesInCatchementArea}
                    recalculatePopulationInCatchmentArea={
                        recalculatePopulationInCatchmentArea
                    }
                    recalculatePopulationAvailable={
                        recalculatePopulationAvailable
                    }
                    setRecalculatePopulationAvailable={
                        setRecalculatePopulationAvailable
                    }
                    canSetInitialCities={canSetInitialCities}
                    setCanSetInitialCities={setCanSetInitialCities}
                />
            )} */}
        </div>
    );
}

export default CatchmentArea;
