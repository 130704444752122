import plotIcon from "../assets/images/parcelle3.png";
import "../style/list-items.css";

function FailedPlotListItem({ plot }) {
    function capitalizeWords(str) {
        return str
            .toLowerCase()
            .split(/[\s,'-]+/)
            .map(function (word) {
                return word.charAt(0).toUpperCase() + word.slice(1);
            })
            .join(" ");
    }

    return (
        <div
            style={{ border: "1px solid red" }}
            className="plot-list-item-container"
        >
            <div className="left">
                <img
                    width={34}
                    height={34}
                    style={{ padding: "0px 24px" }}
                    src={plotIcon}
                    alt="Icone de parcelle"
                />
                <div className="plot-info">
                    <h3 style={{ margin: 0 }}>
                        Parcelle{" "}
                        {(plot.prefixe === "000" || plot.prefixe === undefined
                            ? ""
                            : plot.prefixe + " ") +
                            plot.section +
                            " " +
                            plot.numero}
                    </h3>
                    <span style={{ fontSize: 12 }}>
                        {capitalizeWords(plot.commune) +
                            " (" +
                            plot.code_insee +
                            ")"}
                    </span>
                </div>
            </div>
        </div>
    );
}

export default FailedPlotListItem;
