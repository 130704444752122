import React, { useEffect, useState, useRef } from "react";
import { useGlobalState } from "../pages/app-container";

import "../style/catchment-area-result-popup.css";

const CatchmentAreaResultPopup = ({
    totalPopulationInCatchmentArea,
    userTotalPopulationInCatchmentArea,
    // citiesInCatchementArea,
    padelsInZone,
    calculateUserPopulation,
    // setCitiesInCatchementArea,
    recalculatePopulationInCatchmentArea,
    // recalculatePopulationAvailable,
    // setRecalculatePopulationAvailable,
    // canSetInitialCities,
    // setCanSetInitialCities,
}) => {
    const [citiesInCatchementArea, setCitiesInCatchementArea] = useGlobalState(
        "citiesInCatchementArea"
    );
    const [
        allCitiesProportionalPopulation,
        setAllCitiesProportionalPopulation,
    ] = useGlobalState("allCitiesProportionalPopulation");

    const [citiesInCatchementAreaOrdered, setCitiesInCatchementAreaOrdered] =
        useState([]);
    const previousCitiesInCatchementAreaOrdered = useRef();

    const [recalculatePopulationAvailable, setRecalculatePopulationAvailable] =
        useState(false);

    const [
        totalPopulationCommunesInCatchementArea,
        setTotalPopulationCommunesInCatchementArea,
    ] = useState(0);

    const [nbIndoors, setNbIndoors] = useState(0);

    const [selectedClub, setSelectedClub] = useState(null);

    useEffect(() => {
        if (padelsInZone?.length > 0) {
            let nbIndoorsRes = 0;
            padelsInZone.forEach((padel) => {
                nbIndoorsRes += padel.properties.indoor;
            });
            setNbIndoors(nbIndoorsRes);

            setSelectedClub(padelsInZone?.[0]);
        }
    }, []);

    useEffect(() => {
        const newCitiesOrdered = citiesInCatchementArea
            .map((city) => ({ ...city }))
            .sort((a, b) => {
                return b.populationCalculee - a.populationCalculee;
            });

        setCitiesInCatchementAreaOrdered(newCitiesOrdered);
    }, [citiesInCatchementArea]);

    useEffect(() => {
        if (
            !previousCitiesInCatchementAreaOrdered.current &&
            citiesInCatchementAreaOrdered?.length > 0
        ) {
            previousCitiesInCatchementAreaOrdered.current =
                citiesInCatchementAreaOrdered;

            let totalPopulationCommunesRes = 0;
            citiesInCatchementAreaOrdered.forEach((city) => {
                totalPopulationCommunesRes += city.populationCommune;
            });
            setTotalPopulationCommunesInCatchementArea(
                totalPopulationCommunesRes
            );

            return;
        }

        if (
            citiesInCatchementAreaOrdered?.length > 0 &&
            previousCitiesInCatchementAreaOrdered?.current?.length > 0
        ) {
            const hasDifference = !areArraysEqual(
                citiesInCatchementAreaOrdered,
                previousCitiesInCatchementAreaOrdered.current
            );

            setRecalculatePopulationAvailable(hasDifference);
        }
    }, [citiesInCatchementAreaOrdered]);

    useEffect(() => {
        if (allCitiesProportionalPopulation.use) {
            setCitiesInCatchementArea((prevCities) => {
                return prevCities.map((city) => {
                    return {
                        ...city,
                        calculateProportianalPopulation:
                            allCitiesProportionalPopulation.value
                                ? true
                                : false,
                    };
                });
            });
        }
    }, [allCitiesProportionalPopulation]);

    const handleRecalculate = () => {
        previousCitiesInCatchementAreaOrdered.current =
            citiesInCatchementAreaOrdered;

        recalculatePopulationInCatchmentArea(citiesInCatchementArea);
    };

    const handleClubChange = (event) => {
        const selectedName = event.target.value;

        // Trouver les informations du club sélectionné
        const club = padelsInZone.find(
            (padel) => padel.properties.name === selectedName
        );
        setSelectedClub(club);
    };

    const areArraysEqual = (arr1, arr2) => {
        if (arr1.length !== arr2.length) return false;
        return arr1.every(
            (city, index) =>
                city.calculateProportianalPopulation ===
                arr2[index].calculateProportianalPopulation
        );
    };

    const changePopulationCalculation = (cityId, changeAllCities = false) => {
        if (changeAllCities) {
            setAllCitiesProportionalPopulation({
                use: true,
                value: !allCitiesProportionalPopulation.value,
            });
        } else {
            setAllCitiesProportionalPopulation({
                use: false,
                value: false,
            });

            setCitiesInCatchementArea((prevCities) => {
                return prevCities.map((city) => {
                    if (city.commune.properties.id === cityId) {
                        return {
                            ...city,
                            calculateProportianalPopulation:
                                !city.calculateProportianalPopulation,
                        };
                    }
                    return city;
                });
            });
        }
    };

    return (
        <div className="catchment-area-result-popup-container">
            <div className="catchment-area-result-popup-wrapper">
                <div className="catchment-area-result-popup-header">
                    <h3>Résultats de la zone de chalandise (ZC)</h3>
                </div>
                {calculateUserPopulation ? (
                    <>
                        <div className="catchment-area-result-popup-flex">
                            <div className="catchment-area-result-popup-content">
                                <span className="catchment-area-result-popup-label">
                                    Population dans ZC:
                                </span>
                                <span className="catchment-area-result-popup-value bold">
                                    {" " +
                                        totalPopulationInCatchmentArea.toLocaleString(
                                            "fr-FR"
                                        ) +
                                        " habitants"}
                                </span>
                            </div>
                            <div className="catchment-area-result-popup-content">
                                <span className="catchment-area-result-popup-label">
                                    Population captée dans ZC:
                                </span>
                                <span className="catchment-area-result-popup-value bold">
                                    {" " +
                                        userTotalPopulationInCatchmentArea.toLocaleString(
                                            "fr-FR"
                                        ) +
                                        " habitants"}
                                </span>
                            </div>
                        </div>
                        <div className="catchment-area-result-popup-flex">
                            <div className="catchment-area-result-popup-content">
                                <span className="catchment-area-result-popup-label">
                                    Clubs de padel concurrents dans ZC:
                                </span>
                                <span className="catchment-area-result-popup-value bold">
                                    {" " +
                                        (padelsInZone
                                            ? padelsInZone.length
                                            : "0")}
                                </span>
                            </div>
                            <div className="catchment-area-result-popup-content">
                                <span className="catchment-area-result-popup-label">
                                    Pistes couvertes totales des concurrents:
                                </span>
                                <span className="catchment-area-result-popup-value bold">
                                    {" " + nbIndoors}
                                </span>
                            </div>
                        </div>
                        {padelsInZone?.length > 0 && (
                            <div className="catchment-area-result-popup-flex">
                                <div className="catchment-area-result-popup-content">
                                    <span className="catchment-area-result-popup-label">
                                        {"Clubs concurrents: "}
                                    </span>
                                    <span className="catchment-area-result-popup-value bold">
                                        <select
                                            id="club-select"
                                            value={
                                                selectedClub?.properties
                                                    ?.name || ""
                                            }
                                            onChange={handleClubChange}
                                        >
                                            {padelsInZone.map(
                                                (padel, index) => (
                                                    <option
                                                        key={index}
                                                        value={
                                                            padel.properties
                                                                .name
                                                        }
                                                    >
                                                        {padel.properties.name}
                                                    </option>
                                                )
                                            )}
                                        </select>
                                    </span>
                                </div>
                                <div className="catchment-area-result-popup-content">
                                    <span className="catchment-area-result-popup-label">
                                        Pistes couvertes de{" "}
                                        {selectedClub?.properties?.name}:
                                    </span>
                                    <span className="catchment-area-result-popup-value bold">
                                        {" " + selectedClub?.properties?.indoor}
                                    </span>
                                </div>
                            </div>
                        )}
                    </>
                ) : (
                    <>
                        <div className="catchment-area-result-popup-flex">
                            <div className="catchment-area-result-popup-content">
                                <span className="catchment-area-result-popup-label">
                                    Population des communes située dans ZC:
                                </span>
                                <span className="catchment-area-result-popup-value bold">
                                    {" " +
                                        totalPopulationCommunesInCatchementArea.toLocaleString(
                                            "fr-FR"
                                        ) +
                                        " habitants"}
                                </span>
                            </div>
                            <div className="catchment-area-result-popup-content">
                                <span className="catchment-area-result-popup-label">
                                    Population dans ZC:
                                </span>
                                <span className="catchment-area-result-popup-value bold">
                                    {" " +
                                        totalPopulationInCatchmentArea.toLocaleString(
                                            "fr-FR"
                                        ) +
                                        " habitants"}
                                </span>
                            </div>
                        </div>
                    </>
                )}

                {calculateUserPopulation ? (
                    <>
                        <div className="table-container">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Nom</th>
                                        <th>Population ZC</th>
                                        <th>Aire interceptée</th>
                                        <th>Population captée</th>
                                        <th>Part de population captée</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {citiesInCatchementAreaOrdered.map(
                                        (city) => {
                                            const pourcentageIntersection =
                                                Math.round(
                                                    (city.surfaceIntersection /
                                                        city.surfaceCommune) *
                                                        100
                                                );

                                            return (
                                                <tr
                                                    key={
                                                        city.commune.properties
                                                            .id
                                                    }
                                                >
                                                    <th>
                                                        {
                                                            city.commune
                                                                .properties.nom
                                                        }
                                                    </th>
                                                    <td>
                                                        {city.populationCalculee.toLocaleString(
                                                            "fr-FR"
                                                        )}
                                                    </td>
                                                    <td
                                                        className={
                                                            pourcentageIntersection <=
                                                            25
                                                                ? "low-intersection-percentage"
                                                                : ""
                                                        }
                                                    >
                                                        {pourcentageIntersection +
                                                            " %"}
                                                    </td>
                                                    <td>
                                                        {city.populationPonderee?.toLocaleString(
                                                            "fr-FR"
                                                        )}
                                                    </td>
                                                    <td>
                                                        {Math.round(
                                                            (city.impactUser /
                                                                city.totalImpact) *
                                                                100
                                                        ) + " %"}
                                                    </td>
                                                </tr>
                                            );
                                        }
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="table-container">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Nom</th>
                                        <th>Population commune</th>
                                        <th>Population ZC</th>
                                        <th>Aire interceptée</th>
                                        <th>
                                            Commune{" "}
                                            <div
                                                className={
                                                    "proportional-toggle" +
                                                    (allCitiesProportionalPopulation.value
                                                        ? " active"
                                                        : "")
                                                }
                                            >
                                                <div
                                                    className="toggle-outer"
                                                    onClick={() =>
                                                        changePopulationCalculation(
                                                            -1,
                                                            true
                                                        )
                                                    }
                                                >
                                                    <div className="toggle-inner" />
                                                </div>
                                            </div>{" "}
                                            ZC
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {citiesInCatchementAreaOrdered.map(
                                        (city) => {
                                            const pourcentageIntersection =
                                                Math.round(
                                                    (city.surfaceIntersection /
                                                        city.surfaceCommune) *
                                                        100
                                                );

                                            return (
                                                <tr
                                                    key={
                                                        city.commune.properties
                                                            .id
                                                    }
                                                >
                                                    <th>
                                                        {
                                                            city.commune
                                                                .properties.nom
                                                        }
                                                    </th>
                                                    <td>
                                                        {city.populationCommune.toLocaleString(
                                                            "fr-FR"
                                                        )}
                                                    </td>
                                                    <td>
                                                        {city.populationCalculee.toLocaleString(
                                                            "fr-FR"
                                                        )}
                                                    </td>
                                                    <td
                                                        className={
                                                            pourcentageIntersection <=
                                                            25
                                                                ? "low-intersection-percentage"
                                                                : ""
                                                        }
                                                    >
                                                        {pourcentageIntersection +
                                                            " %"}
                                                    </td>
                                                    <td
                                                        className={
                                                            "proportional-toggle" +
                                                            (city.calculateProportianalPopulation
                                                                ? " active"
                                                                : "")
                                                        }
                                                    >
                                                        <div
                                                            className="toggle-outer"
                                                            onClick={() =>
                                                                changePopulationCalculation(
                                                                    city.commune
                                                                        .properties
                                                                        .id
                                                                )
                                                            }
                                                        >
                                                            <div className="toggle-inner" />
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        }
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <div className="recalculate-container">
                            <button
                                className={
                                    "recalculate" +
                                    (recalculatePopulationAvailable
                                        ? " available"
                                        : "")
                                }
                                // onClick={recalculatePopulationInCatchmentArea}
                                onClick={handleRecalculate}
                            >
                                Recalculer les populations
                            </button>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default CatchmentAreaResultPopup;
